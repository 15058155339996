import Button from "./button";

export default function Header() {
	return (
		<div className="content absolute top-0 left-0 w-full z-50 flex items-center justify-between py-[1.5rem] md:py-[2rem] px-[2rem] md:px-[8rem] bg-transparent border-solid border-b-[1px] border-[#a5ff2b] md:border-none">
			<div className="w-[120px] md:w-auto">
				<img src="/logo.svg" alt="" />
				<span>So-Col</span>
			</div>
			<div className="flex text-[1.2rem] gap-12 hidden md:flex items-center">
				<div className="flex items-center"><a href="#">Home</a></div>
				<div className="flex items-center"><a href="#">Category</a></div>
				<div className="flex items-center"><a href="#">About Us</a></div>
				<div className="flex items-center"><a href="#">Blog</a></div>
				<div className="flex items-center"><a href="#">Contact Us</a></div>
				<Button>Book Now</Button>
				<div className="hidden md:flex gap-4 md:gap-8">
					<a className="w-[20px] md:w-[32px]" href="https://www.facebook.com/people/Big-Time-Ridez/61566848974172/"><img className="w-full" src="/facebook.svg" alt="" /></a>
					<a className="w-[20px] md:w-[32px]" href="https://www.instagram.com/bigtimeridez/"><img className="w-full" src="/instagram.svg" alt="" /></a>
					<a className="w-[20px] md:w-[32px] bg-white rounded-[4px] p-[2px]" href="https://www.tiktok.com/@bigtimeridez"><img className="w-full" src="/tiktok.png" alt="" /></a>
				</div>
			</div>
			<div className="block md:hidden">
				<img src="/drawer.svg" alt="" />
			</div>
		</div>
	)
}